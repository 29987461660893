.portfolio__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item{
    background: var(--bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image{
    position: relative;
    overflow: hidden;
    border-radius: 1.5rem;
    height: 0;
    padding-bottom: 56.25%;
    
}

.portfolio__item-image img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.portfolio__item h3{
    margin: 1.2 0 2rem;
    padding-top: 1rem;
    color: var(--color-primary);
}

.project-description{
    padding-bottom: 1rem;
}

.tech-used{
    color: var(--color-light);
    display: grid;
    grid-template-columns: 1fr 1fr ;
    padding-bottom: 1rem;
}

.portfolio__item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}



@media screen and (min-width: 1800px) {
    .tech-used{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1024px){
    .tech-used li{
        padding-top: 0.7rem;
        width: 70%;
    }
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px){
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}