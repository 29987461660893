header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}



/* ===  CALL TO ACTION === */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/* ===  SOCIALS === */
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
/* =========== IMG ============= */

.me{
    background: linear-gradient(var(--color-primary), transparent);
    /* width: 22rem;
    height: 30rem; */
    width: 22rem;
    height: 24rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
    padding: 2rem 1.5rem .5rem 1.5rem;
}


/* =========== SCROLL DOWN ============= */

.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


/* ============= MEDIA QUERIES ================== */
@media screen and (min-width: 1800px) {
    .me{
        width: 30rem;
        height: 36rem;
        margin-top: 5rem;
        border-radius: 14rem 14rem 0 0;
        overflow: hidden;
        padding: 1.5rem 1.5rem .5rem 1.5rem;
    }

    header{
        padding-top: 12rem;
    }

    .header__socials{
        font-size: 1.5em;
    }

    .scroll__down{
        font-size: 1.2em;
    }

}


@media screen and (max-width: 1024px){

}


@media screen and (max-width: 600px){

    .header__socials, .scroll__down{
        display: none;
    } 
}

