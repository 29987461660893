@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&family=Roboto+Condensed:wght@300;400;700&display=swap');

*{
    padding: 0;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    /* color: #000; */
}

h1{
    font-size: 3rem;  
}
  
h2{
    font-size: 2.5rem;  
}

.nav-container{
    text-align: center;
    align-items: center;
    line-height: 1.2;
    width: 100%;
    height: 7vh;
    background-color: var(--chi-blue);
    display: inline-grid;
    grid-template-columns: repeat(5, 1fr);
    box-shadow: var(--box-shadow-light)
}

.nav-title{
    grid-column-start: 2;
    grid-column-end: 5;
}

.stars img{
    height: 10px;
    /* padding: .5rem 0.5rem; */
}

#stars-start{
    grid-column-start: 1;
}

#stars-right{
    grid-column-start: 5;
}


/* FETCH DATA */
.container__main{
    align-items: center;
    /* padding-top: 2rem; */
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem; */
}

.events-container{
    margin: 2rem auto;
    padding-top: 2rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--soft-gray);
    border: solid #000 3px;
    border-radius: 2rem;
    align-items: center;
}

.event-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    height: 15rem;
    width: 75%;
    background-color: var(--chi-blue);
    border-radius: 2rem;
    border: black 3px solid;
    box-shadow: var(--box-shadow-light);
    overflow: hidden;
}

.event-info{
    text-align: center;
    height: 100%;
    line-height: 2;
}

.event-title{
    margin: 1rem;
}

.event-img{
    position: relative;
    overflow: hidden;
    margin: 1rem 10rem;
    border-radius: 1.5rem;
    border: #000 solid 2px;
}

.event-img img{
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* FOOTER */

footer{
    background-color: var(--chi-blue);
}

.footer__socials a{
    color: var(--color-white);
}
